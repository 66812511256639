<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Category List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :footer-props="{
          'items-per-page-options': [5,10,20,30,50,100,-1],
        }"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:item.action="{item}">
          <v-btn color="grey darken-2" icon dark :to="'/category/edit/'+item.category_id">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="grey darken-2" icon dark @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5">Are you sure delete <b>{{category.name}}</b>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-4" text @click="deleteItem(category)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="primary" fixed fab bottom right to="/category/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
 
import { parseParams, getAlphabets } from "../../plugins/helper";
export default {
  name: "Categorylist",
  data() {
    return {
      loading: false,
      dialog: false,
      category: {
        name: null,
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 50,
        data: [],
      },
      headers: [
        { text: "Category Name", value: "name" },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 50,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios.get("/admin/category/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.category = item;
    },
    deleteItem(item) {
      const index = this.items.data.indexOf(item);
      let _self = this;
      _self.loading = "secondary";
      this.$axios.post("/admin/category/delete", {
        category_id: item.category_id,
      })
        .then((res) => {
          if (res.status) {
            _self.items.data.splice(index, 1);
            _self.dialog = false;
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
  },
};
</script>